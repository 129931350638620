import React from "react";
import '../../assets/css/preloader.css';

export default function Preloader(props) {

    let display = 'none';
    if(props.show){
        display = 'block';
    }

    return (
        <>
            <div className="preloader-wrapper" style={{ display:  display}}>
                <div className="spinner">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>
        </>
    );
}
