import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MainTable from "./components/MainTable";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <MainTable>
			
        </MainTable>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
