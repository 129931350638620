import React, { useRef, useState, useEffect, Fragment } from "react";
import "../assets/css/table-layout.css";
import WorkstationSelectButton from "./subcomponents/WorkStationSelect";
import workstations from "../workstations.json";
import moment from "moment";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Preloader from "./subcomponents/preloader";

import axios from "axios";
import _ from "underscore";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

//import processes from '../assets/sample_datas/processes';

import colors from "../assets/sample_datas/colors";
import { setDate } from "date-fns/esm";
require("dotenv").config();

export default function App(props) {
    // FETCHES
    //! INITIAL STATES
    const [useCurrentWorkstationGroup, setCurrentWorkstationGroup] =
        useState("");
    const [useCurrentDate, setCurrentDate] = useState("2021-02-19");
    const [useDayMaps, setDayMaps] = useState([]);
    const [useWillBeShownDayCount, setWillBeShownDayCount] = useState(
        process.env.REACT_APP_WILL_BE_SHOWN_DAY_COUNT
    );
    const [useShiftsCountOfADay, setShiftsCountOfADay] = useState(
        process.env.REACT_APP_SHIFTS_COUNT_OF_A_DAY
    );
    const [usePartsOfAShift, setPartsOfAShift] = useState(
        process.env.REACT_APP_SILIKA_PART_COUNT
    );
    const [usePartsNames, setPartsNames] = useState([]);
    const [useWorkstations, setWorkstations] = useState([]);
    const [useShifts, setShifts] = useState(["F", "S", "N"]);
    const [useProcesses, setProcesses] = useState({});
    const [useRowHeight, setRowHeight] = useState(0);
    const [useCells, setCells] = useState([]);
    const [useCellCountPerWorkstation, setCellCountPerWorkstation] = useState(
        useWillBeShownDayCount * useShiftsCountOfADay * usePartsOfAShift
    );
    const [useSelectedWorkstation, setSelectedWorkstation] = useState(
        "silikapr-workstations"
    );
    const [useProcessesURL, setProcessesURL] = useState(
        process.env.REACT_APP_ROOT +
            "/monitor-rest-api?will_be_shown_day_count=15&workstation_group=silikapr-workstations"
    );
    const [useDateURL, setDateURL] = useState(
        process.env.REACT_APP_ROOT + "/monitor-date"
    );
    const [usePreloaderShow, setPreloaderShow] = useState(false);
    const [useReRender, setReRender] = useState(Math.random());
    const [useModalStyle, setModalStyle] = useState(getModalStyle);
    const [useModalOpen, setModalOpen] = useState(false);
    const [useWorkstationFilterModalOpen, setWorkstationFilterModalOpen] =
        useState(false);
    const [useSelectedDateStart, setSelectedDateStart] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [useSelectedDateEnd, setSelectedDateEnd] = useState(
        moment(new Date())
            .add(useWillBeShownDayCount - 1, "days")
            .format("YYYY-MM-DD")
    );
    const [useDateRange, setDateRange] = useState([
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date())
            .add(useWillBeShownDayCount - 1, "days")
            .format("YYYY-MM-DD"),
    ]);
    const [useFilteredWorkstations, setFilteredWorkstations] = useState([]);
    const [useShowWorkstationDeleteButtons, setShowWorkstationDeleteButtons] =
        useState("none");

    const [useCheckedWorkstations, setCheckedWorkstations] = useState([]);
    const [useFullWorkstations, setFullWorkstations] = useState([]);

    //!

    //! VARIABLES
    const shifts = ["F", "S", "N"];

    const partCounts = [
        process.env.REACT_APP_SILIKA_PART_COUNT,
        process.env.REACT_APP_SCHAMOTTE_PART_COUNT,
        process.env.REACT_APP_HANDFORMEREI_PART_COUNT,
        process.env.REACT_APP_ENDBEARBEITUNG_PART_COUNT,
    ];

    const parts = [];
    for (let i = 0; i < usePartsOfAShift; i++) {
        parts.push("");
    }

    const workstationNames = [
        "silikapr-workstations",
        "schamottepr-workstations",
        "configration-handformerei",
        "configration-endbearbeitung",
    ];

    const workstationPrepends = ["p-si-", "p-sa-"];

    //!

    //! FUNCTIONS

    const removePrependWorkstation = (name) => {
        let replacedString = name;
        workstationPrepends.forEach((item, key) => {
            replacedString = replacedString.replace(item, '');
        });

        return replacedString;
    }

    const formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    const dateRangeConfirmButton = () => {
        return (
            <div className="confirm-button-outer">
                <HtmlTooltip title="Bestätigen" onClick={handleSettingsConfirm}>
                    <ExitToAppIcon />
                </HtmlTooltip>
            </div>
        );
    };

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }))(Tooltip);

    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    /**
     * {
     *  date: '2021-02-15',
     *  startPart: 'm1' || 'm'
     *  partCount: 10
     * }
     */
    function colSpanBetween(
        startDatePart,
        endDatePart,
        partsWithNames = false
    ) {
        let partCountOfADay = useShiftsCountOfADay * usePartsOfAShift;

        let totalDayDifference = moment(endDatePart.date).diff(
            moment(startDatePart.date),
            "days"
        );

        if (partsWithNames) {
            if (startDatePart.startPart.length == 1) {
                startDatePart.startPart = startDatePart.startPart + 1;
            }

            if (endDatePart.startPart.length == 1) {
                endDatePart.startPart = endDatePart.startPart + 1;
            }

            startDatePart.startPart = usePartsNames.indexOf(
                startDatePart.startPart
            );
            endDatePart.startPart = usePartsNames.indexOf(
                endDatePart.startPart
            );
        }

        let difference =
            (totalDayDifference - 1) * partCountOfADay +
            (partCountOfADay -
                (startDatePart.startPart + startDatePart.partCount)) +
            endDatePart.startPart;

        return difference;
    }

    function getColor(i) {
        return colors[i % colors.length];
    }

    const blankCellFill = (totalColSpan) => {
        return (
            <TableCell
                style={{ tableLayout: "fixed" }}
                className="planning-cell blank-process"
                colSpan={totalColSpan}
                key={"cell-blank-" + _.random(99999999)}
            ></TableCell>
        );
    };

    const processCellFill = (
        totalColSpan,
        key,
        backgroundColor,
        borderColor,
        innerHTML,
        tooltip,
        is_fertig = true,
        is_almost_due_date = false,
        finish_status = 0,
        height = 20,
        process_id = "",
        is_block_zone = false,
        is_group = false,
        almostDueDateTime = false
    ) => {
        let fertigStyle = "";
        let almostDueDate = "";
        if (is_fertig || is_block_zone) fertigStyle = "fertig";
        if (is_almost_due_date) almostDueDate = "almost-due-date";

        function finishStatus(
            finishPercentage,
            borderColor,
            backgroundColor,
            inner
        ) {
            let remainPercentage = 100 - finishPercentage;
            if (finishPercentage == 0) {
                return <>{inner()}</>;
            } else {
                return (
                    <div
                        style={{
                            background:
                                "linear-gradient(to right, " +
                                borderColor +
                                " " +
                                finishPercentage +
                                "%, " +
                                backgroundColor +
                                " 0%)",
                            position: "relative",
                            height: "100%",
                            width: 100 + "%",
                        }}
                    >
                        <div
                            className="finish-status-show"
                            style={{ backgroundColor: borderColor }}
                        >
                            {finishPercentage} %
                        </div>
                        {inner()}
                    </div>
                );
            }
        }

        let fontSize = 0.7;
        if (totalColSpan < 3) {
            fontSize = 0.5;
        }

        let isFertigStyle = "green";
        let isFertigText = "yes";
        if (!is_fertig) {
            isFertigStyle = "red";
            isFertigText = "no";
        }

        let tooltipShow = "block";
        if (is_block_zone) tooltipShow = "none";

        let almostDueDateView = () => {
            return <></>;
        };
        if (almostDueDateTime) {
            almostDueDateView = () => {
                let textColor = "green";
                if (almostDueDate) textColor = "red";
                return (
                    <>
                        <br />
                        Bereitstellungsdatum{" "}
                        <span style={{ color: { textColor } }}>
                            {almostDueDateTime}
                        </span>
                    </>
                );
            };
        }

        if (is_block_zone) {
            return (
                <TableCell
                    className="planning-cell process"
                    colSpan={totalColSpan}
                    key={key}
                >
                    <div
                        className={
                            "process-job " + fertigStyle + " " + almostDueDate
                        }
                        style={{
                            backgroundColor: backgroundColor,
                            borderColor: borderColor,
                            height: height,
                        }}
                    >
                        {finishStatus(
                            finish_status,
                            borderColor,
                            backgroundColor,
                            () => {
                                return (
                                    <p
                                        style={{ fontSize: fontSize + "rem" }}
                                        className="process-job-inner"
                                    >
                                        {innerHTML()}
                                    </p>
                                );
                            }
                        )}
                    </div>
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    className="planning-cell process"
                    colSpan={totalColSpan}
                    key={key}
                >
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography
                                    color="inherit"
                                    style={{ textAlign: "center" }}
                                >
                                    {process_id}
                                </Typography>
                                {tooltip()}
                                <br />
                                {/*
                                Anzahl der Teile:{" "}
                                <span style={{ color: "green" }}>
                                    {totalColSpan}
                                </span>
                                <br />
                                Prozent beenden:{" "}
                                <span style={{ color: "green" }}>
                                    {finish_status}%
                                </span>
                                <br />
                                Fertig:{" "}
                                <span style={{ color: isFertigStyle }}>
                                    {isFertigText}
                                </span>
                                */}
                                {almostDueDateView()}
                            </React.Fragment>
                        }
                    >
                        <div
                            className={
                                "process-job " +
                                fertigStyle +
                                " " +
                                almostDueDate
                            }
                            style={{
                                backgroundColor: backgroundColor,
                                borderColor: borderColor,
                                height: height,
                            }}
                        >
                            {finishStatus(
                                finish_status,
                                borderColor,
                                backgroundColor,
                                () => {
                                    return (
                                        <p
                                            style={{
                                                fontSize: fontSize + "rem",
                                            }}
                                            className="process-job-inner"
                                        >
                                            {innerHTML()}
                                        </p>
                                    );
                                }
                            )}
                        </div>
                    </HtmlTooltip>
                </TableCell>
            );
        }
    };

    const groupFinishStatus = (
        finishPercentage,
        borderColor,
        backgroundColor,
        inner
    ) => {
        if (finishPercentage == 0) {
            return <>{inner}</>;
        } else {
            return (
                <div
                    className="group-item-finish-status"
                    style={{
                        background:
                            "linear-gradient(to right, " +
                            borderColor +
                            " " +
                            finishPercentage +
                            "%, " +
                            backgroundColor +
                            " 0%)",
                        position: "relative",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <p className="group-item-inner">{inner}</p>
                </div>
            );
        }
    };

    let groupRetObj = (
        processes,
        height,
        color,
        border,
        key,
        finish_status
    ) => {
        return (
            <div
                className="group-item"
                style={{
                    backgroundColor: color,
                    borderColor: border,
                    height: height / Object.keys(processes).length,
                }}
            >
                {groupFinishStatus(finish_status, border, color, key)}
            </div>
        );
    };

    const processesCellGroupFill = (
        processes,
        key,
        description,
        height = 20
    ) => {
        let groupInnerElements = [];

        for (const [key, value] of Object.entries(processes)) {
            let el = value;
            let fertigStyle = "";
            let almostDueDate = "";
            if (el.is_fertig) fertigStyle = "fertig";
            if (el.is_almost_due_date) almostDueDate = "almost-due-date";

            let processColor = processesColors(key);

            let retObj = groupRetObj(
                processes,
                height,
                processColor.color,
                processColor.border,
                key,
                el.finish_status
            );

            groupInnerElements.push(retObj);
        }

        let processesSize = Object.keys(processes).length;
        let fontSize = 0.8;

        if (processesSize > 3) {
            fontSize = 0.6;
        } else if (processesSize > 4) {
            fontSize = 0.5;
        } else if (processesSize > 5) {
            fontSize = 0.4;
        }

        return (
            <TableCell
                className="planning-cell process group-process"
                colSpan={1}
                key={key}
                style={{
                    width: "1px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }}
            >
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography
                                color="inherit"
                                style={{ textAlign: "center" }}
                            ></Typography>
                            {description()}
                        </React.Fragment>
                    }
                >
                    <div
                        className={"process-job group-job"}
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #f00",
                            height: height,
                            fontSize: fontSize + "rem",
                        }}
                    >
                        {groupInnerElements.map(
                            (groupElement, groupElementKey) => {
                                return groupElement;
                            }
                        )}
                    </div>
                </HtmlTooltip>
            </TableCell>
        );
    };

    //:p Processes Colors
    const processesColors = (processId, isBlocked = false) => {
        if (isBlocked) {
            return {
                color: "#aaaaaa",
                border: "#777777",
            };
        } else {
            let processes = useProcesses;
            let processArray = [];
            for (const [key, value] of Object.entries(processes)) {
                for (let i = 0; i < value.length; i++) {
                    let el = value[i];
                    if (value[i].is_group) {
                        for (const [key2, value2] of Object.entries(
                            el.processes
                        )) {
                            if (processArray.indexOf(key2) == -1) {
                                processArray.push(key2);
                            }
                        }
                    } else {
                        if (processArray.indexOf(el.process_id) == -1) {
                            processArray.push(el.process_id);
                        }
                    }
                }
            }

            //console.log(processArray);
            return getColor(processArray.indexOf(processId));
        }
    };

    const isWeekendStyle = (day) => {
        let res = moment(day).day();
        let isWeekend = false;
        if (res == 0 || res == 6) isWeekend = true;

        if (!isWeekend) return "";
        else return " weekend ";
    };

    //isWeekend("2021-02-21");
    //!

    //! EFFECTS

    // useEffect(() => {
    //     setProcessesURL(process.env.REACT_APP_ROOT + '/monitor-rest-api?will_be_shown_day_count=15&workstation_group=' + useSelectedWorkstation);
    //     setPartsOfAShift(partCounts[workstationNames.indexOf(useSelectedWorkstation)]);
    //     //console.log('PART COUNT: ' + partCounts[workstationNames.indexOf(useSelectedWorkstation)]);

    // }, [useSelectedWorkstation, useCurrentDate]);

    useEffect(() => {
        setDateRange([
            formatDate(useSelectedDateStart),
            formatDate(useSelectedDateEnd),
        ]);
    }, [useSelectedDateStart, useSelectedDateEnd]);

    useEffect(() => {
        setPreloaderShow(true);
        axios({
            method: "GET",
            url: useDateURL,
        })
            .then((respDate) => {
                setCurrentDate(respDate.data);
            })
            .then(() => {
                setPreloaderShow(false);
            });
    }, []);

    useEffect(() => {
        console.log("WORKSTATION CHANGED " + useSelectedWorkstation);
        setPreloaderShow(true);
        let dateRange =
            "&dateStart=" + useDateRange[0] + "&dateEnd=" + useDateRange[1];

        let filter_workstations_URL =
            process.env.REACT_APP_ROOT + "/monitor-filter-workstations";
        axios({
            method: "GET",
            url: filter_workstations_URL,
        })
            .then((filters) => {
                setFilteredWorkstations(filters.data);
                console.log(filters);
                return filters.data;
            })
            .then((filters) => {
                axios({
                    method: "GET",
                    url: useProcessesURL + dateRange,
                })
                    .then((processesJSON) => {
                        console.log("processesJSON");
                        console.log(processesJSON);
                        setProcesses(processesJSON.data);
                        let workstations = Object.keys(processesJSON.data);
                        let filteredWorkstations = [];
                        let checkedWorkstationsArray = [];
                        let fullWorkstations = [];
                        workstations.forEach((workstation) => {
                            if (
                                workstation.indexOf("bahnhof") == -1 &&
                                workstation.indexOf("xx") == -1 &&
                                workstation != ""
                            ) {
                                fullWorkstations.push(workstation);
                                if (filters.indexOf(workstation) == -1) {
                                    filteredWorkstations.push(workstation);
                                }

                                if (filters.indexOf(workstation) == -1) {
                                    checkedWorkstationsArray.push(true);
                                } else {
                                    checkedWorkstationsArray.push(false);
                                }
                            }
                        });
                        filteredWorkstations.push("");
                        setWorkstations(filteredWorkstations);
                        setCheckedWorkstations(checkedWorkstationsArray);
                        setFullWorkstations(fullWorkstations);
                        return filteredWorkstations;
                    })
                    .then((filteredWorkstations) => {
                        setPreloaderShow(false);
                    })
                    .then(() => {
                        setTimeout(() => {
                            setReRender(Math.random);
                        }, process.env.REACT_APP_RERENDER_TIMEOUT);
                    });
            });
    }, [useProcessesURL, useReRender]);

    useEffect(() => {
        let rowCount = useWorkstations.length - 1;
        setRowHeight((window.innerHeight - 200) / rowCount);
    }, [useWorkstations]);

    //:p loop days
    useEffect(() => {
        setProcessesURL(
            process.env.REACT_APP_ROOT +
                "/monitor-rest-api?will_be_shown_day_count=15&workstation_group=" +
                useSelectedWorkstation
        );
        setPartsOfAShift(
            partCounts[workstationNames.indexOf(useSelectedWorkstation)]
        );
        //console.log(partCounts[workstationNames.indexOf(useSelectedWorkstation)]);
        let currentDate = moment(useCurrentDate);
        let daysMaps = [];
        for (let i = 0; i < useWillBeShownDayCount; i++) {
            if (i == 0)
                daysMaps.push(currentDate.add(0, "days").format("DD-MM-YYYY"));
            else daysMaps.push(currentDate.add(1, "days").format("DD-MM-YYYY"));
        }
        setDayMaps(daysMaps);
        /*
        setDateRangeValue([
            useCurrentDate,
            moment(useCurrentDate)
                .add(useWillBeShownDayCount - 1, "days")
                .format("YYYY-MM-DD"),
        ]);
        */
        // console.log(useDateRangeValue);
        let partsNames = [];

        //console.log(useShiftsCountOfADay * usePartsOfAShift);

        for (
            let i = 1;
            i <= (useShiftsCountOfADay * usePartsOfAShift) / 3;
            i++
        ) {
            partsNames.push("f" + i);
        }
        for (
            let i = 1;
            i <= (useShiftsCountOfADay * usePartsOfAShift) / 3;
            i++
        ) {
            partsNames.push("m" + i);
        }
        for (
            let i = 1;
            i <= (useShiftsCountOfADay * usePartsOfAShift) / 3;
            i++
        ) {
            partsNames.push("n" + i);
        }

        setPartsNames(partsNames);
    }, [
        useCurrentDate,
        useSelectedWorkstation,
        usePartsOfAShift,
        useShiftsCountOfADay,
        useWillBeShownDayCount,
    ]);

    //! HANDLES
    //:p Workstation selection
    const onChangeWorkstation = (value) => {
        setSelectedWorkstation(workstationNames[value]);
    };

    //:p Slot filler
    const handleFillWorkstation = (workstation) => {
        workstation = workstation.toLowerCase();

        // Initial
        let processes = useProcesses[workstation];
        let totalCellCount =
            useWillBeShownDayCount * useShiftsCountOfADay * usePartsOfAShift;
        let currentDay = useCurrentDate;

        let doesNotExistProcess = [];
        if (processes == null) {
            for (let j = 0; j < totalCellCount; j++) {
                let blankCell = {
                    colSpan: 1,
                    type: "",
                    info: "blankRow",
                    html: () => {
                        return blankCellFill(1);
                    },
                };

                doesNotExistProcess.push(blankCell);
            }
            return doesNotExistProcess;
        } else {
            // Sort them
            let sorted = _.sortBy(processes, function (o) {
                return o.starts_at;
            });

            processes = sorted;

            let resArr = [];

            for (let i = 0; i < processes.length; i++) {
                if (i == 0) {
                    // Get the blank cells before first process

                    let startDate = {
                        date: moment(useCurrentDate)
                            .add(-1, "days")
                            .format("YYYY-MM-DD"),
                        startPart: usePartsNames[usePartsNames.length - 1],
                        partCount: 1,
                    };
                    let endDate = {
                        date: processes[i].starts_at,
                        startPart: processes[i].starts_at_cell,
                        partCount: processes[i].partCount,
                    };

                    let totalColSpan = colSpanBetween(startDate, endDate, true);
                    // console.log("Before Total Col Span: " + totalColSpan);
                    if (totalColSpan > 0) {
                        for (let j = 0; j < totalColSpan; j++) {
                            let blankBefore = {
                                colSpan: totalColSpan,
                                type: "",
                                info: "before",
                                html: () => {
                                    return blankCellFill(1);
                                },
                            };
                            resArr.push(blankBefore);
                        }
                    }
                } else {
                    // Get the blank cells before middle processes
                    let startDate = {
                        date: processes[i - 1].starts_at,
                        startPart: processes[i - 1].starts_at_cell,
                        partCount: processes[i - 1].partCount,
                    };

                    let endDate = {
                        date: processes[i].starts_at,
                        startPart: processes[i].starts_at_cell,
                        partCount: processes[i].partCount,
                    };

                    let totalColSpan = colSpanBetween(startDate, endDate, true);

                    if (totalColSpan > 0) {
                        for (let j = 0; j < totalColSpan; j++) {
                            let blankBefore = {
                                colSpan: totalColSpan,
                                type: "",
                                info: "between",
                                html: () => {
                                    return blankCellFill(1);
                                },
                            };

                            resArr.push(blankBefore);
                        }
                    }
                }

                // Set the Process Col
                if (processes[i].is_group) {
                    // if is group
                    let componentTooltipHTML = () => {
                        let desc = processes[i].description;
                        desc = desc.split(",");
                        console.log(desc);
                        return (
                            <>
                                {desc.map((process_id) => (
                                    <>
                                        {process_id}
                                        <br />
                                    </>
                                ))}
                            </>
                        );
                    };

                    resArr.push({
                        colSpan: 1,
                        type: "process",
                        info: "process",
                        html: () => {
                            return processesCellGroupFill(
                                processes[i].processes,
                                "cell-process-group-" +
                                    processes[i].starts_at +
                                    "-" +
                                    processes[i].starts_at_cell +
                                    "-" +
                                    _.random(9999999),
                                componentTooltipHTML,
                                useRowHeight - 10
                            );
                        },
                    });
                } else {
                    // if is not group

                    let componentHTML = () => {
                        let processHTML = processes[i].description;
                        return (
                            <>
                                {/* {processes[i].title} 
                                <br />
                                {processes[i].process_id} */}
                                {processes[i].description}
                            </>
                        );
                    };

                    let tooltip = () => {
                        let processHTML = processes[i].description;
                        if (processes[i].details) {
                            return (
                                <>
                                    Kundenauftrag:{" "}
                                    <span>
                                        {processes[i].details.kundenauftrag}
                                    </span>
                                    <br />
                                    Position:{" "}
                                    <span>{processes[i].details.position}</span>
                                    <br />
                                    Bereitstellungsdatum:{" "}
                                    <span>
                                        {
                                            processes[i].details
                                                .bereitstellungsdatum
                                        }
                                    </span>
                                    <br />
                                    Materialkurztext:{" "}
                                    <span>
                                        {processes[i].details.materialkurztext}
                                    </span>
                                    <br />
                                    Prozessauftrag:{" "}
                                    <span>
                                        {processes[i].details.prozessauftrag}
                                    </span>
                                    <br />
                                    Abformung Rest ST:{" "}
                                    <span>
                                        {processes[i].details.abformung_rest_st}
                                    </span>
                                    <br />
                                    Abformung Rest TO:{" "}
                                    <span>
                                        {processes[i].details.abformung_rest_to}
                                    </span>
                                    <br />
                                    Vorgangsmenge:{" "}
                                    <span>
                                        {processes[i].details.vorgangsmenge}
                                    </span>
                                    <br />
                                </>
                            );
                        } else {
                            return <></>;
                        }
                    };

                    resArr.push({
                        colSpan: processes[i].partCount,
                        type: "process",
                        info: "process",
                        html: () => {
                            let is_block_zone = processes[i].is_block_zone;
                            return processCellFill(
                                processes[i].partCount,
                                "cell-process-" +
                                    processes[i].starts_at +
                                    "-" +
                                    processes[i].starts_at_cell,
                                processesColors(
                                    processes[i].process_id,
                                    is_block_zone
                                ).color,
                                processesColors(
                                    processes[i].process_id,
                                    is_block_zone
                                ).border,
                                componentHTML,
                                tooltip,
                                processes[i].is_fertig,
                                processes[i].is_almost_due_date,
                                processes[i].finish_status,
                                useRowHeight - 20,
                                processes[i].process_id,
                                is_block_zone,
                                false,
                                processes[i].almost_due_date
                            );
                        },
                    });
                }

                if (i == processes.length - 1) {
                    // Get the blank cells after last process
                    let startDate = {
                        date: processes[i].starts_at,
                        startPart: processes[i].starts_at_cell,
                        partCount: processes[i].partCount,
                    };

                    let endDate = {
                        date: moment(useCurrentDate)
                            .add(useWillBeShownDayCount, "days")
                            .format("YYYY-MM-DD"),
                        startPart: "f",
                        partCount: 1,
                    };

                    let totalColSpan = colSpanBetween(startDate, endDate, true);

                    if (totalColSpan > 0) {
                        for (let j = 0; j < totalColSpan; j++) {
                            let blankAfter = {
                                colSpan: totalColSpan,
                                type: "",
                                info: "end",
                                html: () => {
                                    return blankCellFill(1);
                                },
                            };
                            resArr.push(blankAfter);
                        }
                    }
                }
            }

            for (
                let j = 0;
                j <
                useWillBeShownDayCount *
                    useShiftsCountOfADay *
                    usePartsOfAShift;
                j++
            ) {
                let blankBefore = {
                    colSpan: 1,
                    type: "",
                    info: "before",
                    html: () => {
                        return blankCellFill(1);
                    },
                };
                resArr.push(blankBefore);
            }

            return resArr;
        }
    };
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleWorkstationFilterModalOpen = () => {
        setWorkstationFilterModalOpen(true);
    };

    const handleWorkstationFilterModalClose = () => {
        setWorkstationFilterModalOpen(false);
    };

    const handleStartDateChange = (date) => {
        setSelectedDateStart(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedDateEnd(date);
    };

    const handleSettingsConfirm = () => {
        setCurrentDate(useDateRange[0]);
        setWillBeShownDayCount(
            moment(useDateRange[1]).diff(moment(useDateRange[0]), "days") + 1
        );

        //:y Filter
        let willBeAddedWorkstationsToFilter = [];
        let willBeDeletedWorkstationsToFilter = [];

        useFullWorkstations.forEach((workstation, workstationIndex) => {
            if (!useCheckedWorkstations[workstationIndex]) {
                willBeAddedWorkstationsToFilter.push(workstation);
            } else {
                willBeDeletedWorkstationsToFilter.push(workstation);
            }
        });

        console.log(willBeAddedWorkstationsToFilter);
        console.log(willBeDeletedWorkstationsToFilter);

        willBeAddedWorkstationsToFilter =
            willBeAddedWorkstationsToFilter.join(",");
        willBeDeletedWorkstationsToFilter =
            willBeDeletedWorkstationsToFilter.join(",");

        axios({
            method: "GET",
            url:
                process.env.REACT_APP_ROOT +
                "/monitor-filter-workstations?add_batch=" +
                willBeAddedWorkstationsToFilter,
        }).then((responseAdd) => {
            console.log("Response Add");
            console.log(responseAdd.data);
            axios({
                method: "GET",
                url:
                    process.env.REACT_APP_ROOT +
                    "/monitor-filter-workstations?delete_batch=" +
                    willBeDeletedWorkstationsToFilter,
            })
                .then((responseDelete) => {
                    console.log("Response Delete");
                    console.log(responseDelete.data);
                    setFilteredWorkstations(responseDelete.data);
                })
                .then(() => {
                    setReRender(Math.random);
                });
        });
    };

    const handleToggleWorkstationDeleteButtons = () => {
        if (useShowWorkstationDeleteButtons == "none")
            setShowWorkstationDeleteButtons("block");
        else setShowWorkstationDeleteButtons("none");
    };

    const handleOnWorkstationFilterChange = (index) => {
        setCheckedWorkstations(
            useCheckedWorkstations.map((v, i) => (i === index ? !v : v))
        );
    };

    //handleFillWorkstation('P-SI-H2');
    //!

    //! RENDER
    return (
        <>
            <Preloader show={usePreloaderShow} />
            <div className="main-wrapper">
                <TableContainer
                    className="table-container"
                    component={Paper}
                    style={{ overflowX: "visible" }}
                >
                    <Table
                        style={{ minWidth: "650px" }}
                        aria-label="caption table"
                    >
                        {/* <caption>PD Otto Refactories</caption> */}

                        <TableBody>
                            <TableRow className="main-table-row">
                                <TableCell className="main-table-main-cell">
                                    <TableRow
                                        key="head-days-row"
                                        align="center"
                                        className="head-days-row"
                                    >
                                        <TableCell
                                            rowSpan={2}
                                            className="workstation-selection-button"
                                            style={{ padding: "4px" }}
                                        >
                                            <WorkstationSelectButton
                                                onChangeWorkstationKey={
                                                    onChangeWorkstation
                                                }
                                            />
                                            <div className="date-range-outer">
                                                <HtmlTooltip
                                                    title="Arbeitsplatz-Filter"
                                                    onClick={
                                                        handleWorkstationFilterModalOpen
                                                    }
                                                >
                                                    <DoneAllIcon />
                                                </HtmlTooltip>
                                                <HtmlTooltip
                                                    title="Datumsbereich auswählen"
                                                    onClick={handleModalOpen}
                                                >
                                                    <DateRangeIcon className="date-range-icon" />
                                                </HtmlTooltip>
                                                {dateRangeConfirmButton()}
                                            </div>
                                        </TableCell>
                                        {useDayMaps.map((day, dayIndex) => (
                                            <TableCell
                                                style={{ width: "30%" }}
                                                align="center"
                                                key={"head-" + dayIndex}
                                                className={
                                                    "head-day-cell" +
                                                    isWeekendStyle(day)
                                                }
                                                colSpan={
                                                    useShiftsCountOfADay *
                                                    usePartsOfAShift
                                                }
                                            >
                                                {day}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow
                                        key="head-shifts-row"
                                        align="center"
                                        className="head-shifts-row"
                                    >
                                        {useDayMaps.map((day, dayIndex) => {
                                            return shifts.map(
                                                (shift, shiftIndex) => {
                                                    return (
                                                        <TableCell
                                                            className="head-shift-cell"
                                                            align="center"
                                                            key={
                                                                "head-" +
                                                                dayIndex +
                                                                "-" +
                                                                shiftIndex
                                                            }
                                                            colSpan={
                                                                usePartsOfAShift
                                                            }
                                                        >
                                                            {shift}
                                                        </TableCell>
                                                    );
                                                }
                                            );
                                        })}
                                    </TableRow>
                                    {useWorkstations.map(
                                        (workstation, workstationIndex) => {
                                            return (
                                                <TableRow
                                                    className="workstation-row"
                                                    key={workstation + "-row"}
                                                    style={{
                                                        height: useRowHeight,
                                                    }}
                                                >
                                                    <TableCell
                                                        align="center"
                                                        className="workstation-name-cell"
                                                        key={
                                                            "workstation-head-" +
                                                            workstationIndex
                                                        }
                                                        colSpan={1}
                                                    >
                                                        {removePrependWorkstation(workstation)}
                                                    </TableCell>
                                                    {handleFillWorkstation(
                                                        workstation
                                                    ).map((part, partIndex) => {
                                                        return part.html();
                                                    })}
                                                    {/* 
                                                {useDayMaps.map((day, dayIndex) => {
                                                    return shifts.map((shift, shiftIndex) => {
                                                        
                                                        return parts.map((part, partIndex) => {
                                                                return (
                                                                    <TableCell className="planning-cell" colSpan={ 1 } key={"cell-"+dayIndex+"-"+shiftIndex+"-" +partIndex}>
                                                                        {part}
                                                                    </TableCell>
                                                                );
                                                        });
                                                        
                                                    });
                                                })}
                                                */}
                                                </TableRow>
                                            );
                                        }
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Modal
                open={useModalOpen}
                onClose={handleModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="date-range-modal"
            >
                <Fade in={useModalOpen}>
                    <div style={useModalStyle} className="modal-paper">
                        <h2 id="simple-modal-title">Datumsbereich auswählen</h2>

                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog-start"
                                label="Datumsbereich Start"
                                format="DD-MM-YYYY"
                                value={useSelectedDateStart}
                                onChange={(date) => handleStartDateChange(date)}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog-end"
                                label="Datumsbereich Ende"
                                format="DD-MM-YYYY"
                                value={useSelectedDateEnd}
                                onChange={(date) => handleEndDateChange(date)}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Fade>
            </Modal>
            <Modal
                open={useWorkstationFilterModalOpen}
                onClose={handleWorkstationFilterModalClose}
                aria-labelledby="workstation-filter-simple-modal-title"
                aria-describedby="workstation-filter-simple-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="date-range-modal"
            >
                <Fade in={useWorkstationFilterModalOpen}>
                    <div style={useModalStyle} className="modal-paper">
                        <h2 id="simple-modal-title">Arbeitsplatz-Filter</h2>

                        <ul>
                            {useFullWorkstations.map(
                                (workstation, workstationIndex) => {
                                    return (
                                        <li
                                            key={
                                                "filter-workstation-item-" +
                                                workstationIndex
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            useCheckedWorkstations[
                                                                workstationIndex
                                                            ]
                                                        }
                                                        onChange={() =>
                                                            handleOnWorkstationFilterChange(
                                                                workstationIndex
                                                            )
                                                        }
                                                        name={workstation}
                                                        color="primary"
                                                    />
                                                }
                                                label={workstation}
                                            />
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>
                </Fade>
            </Modal>

            {props.children}
        </>
    );
}
