let colors = [
    {
        color: '#18b38d',
        border: '#014031'
    },
    {
        color: '#fc3a70',
        border: '#7c0627'
    },
    {
        color: '#afd909',
        border: '#536702'
    },
    {
        color: '#1395ff',
        border: '#074a80'
    },
    {
        color: '#8b8b8b',
        border: '#404040'
    },
    {
        color: '#fdae2e',
        border: '#78500f'
    },
    {
        color: '#fb2532',
        border: '#780d14'
    },
    {
        color: '#8b3fff',
        border: '#492088'
    },
    {
        color: '#cddc39',
        border: '#5c640f'
    },
    {
        color: '#4caf50',
        border: '#275e29'
    },
    {
        color: '#795548',
        border: '#3b2922'
    },

    {
        color: '#3f51b5',
        border: '#1c2558'
    },
    {
        color: '#607d8b',
        border: '#2d3b41'
    },
    {
        color: '#223',
        border: '#13131a'
    },
    {
        color: '#829254',
        border: '#1e2213'
    },
];

export default colors;